<template>
  <div class="content">
    <Breadcrumb :data="nav" />
    <Box v-loading="loading" class="pane">
      <div slot="header" class="pane-header">
        <h4 class="title">{{ detail.title | textFilter }}</h4>
        <p class="sub-title">
          <span>{{ $t('122cefe') }}：</span>
          <span style="margin-right: 40px">{{
            detail.createTime | textFilter
          }}</span>
          <span>{{ $t('52c01f1') }}：</span>
          <span>{{ detail.source | textFilter }}</span>
        </p>
      </div>
      <div class="pane-body">
        <div v-html="detail.description"></div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Breadcrumb from '@/components/Breadcrumb'
import Api from '@/api/information.js'

export default {
  components: {
    Box,
    Breadcrumb,
  },
  data() {
    return {
      loading: true,
      detail: {},
    }
  },
  computed: {
    nav() {
      return [
        { name: this.$t('3a505c4'), path: '/information' },
        { name: this.$t('4f3d806'), path: '/information/notice' },
        { name: this.$t('8c7d6cd'), path: '' },
      ]
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const id = this.$route.query.id
      this.loading = true
      Api.getNoticeDetail(id)
        .then((res) => {
          this.detail = res
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 0 auto;
}

.pane-header {
  padding: 30px;
  border-bottom: 1px solid #eee;

  .title {
    font-size: 20px;
    color: #333333;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
  }

  .sub-title {
    font-size: 13px;
    color: #999999;
    text-align: center;
  }
}

.pane-body {
  font-size: 14px;
  color: #999999;
  line-height: 1.5;

  p {
    padding-bottom: 30px;
  }
}
</style>
